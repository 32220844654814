import React from 'react'
import { Link } from 'react-router-dom'

const NavList = ({ ul_class, li_class , close_click }) => {
    return (
        <ul className={`${ul_class}`}>
            <li className={`${li_class}`}> <Link className='block'  onClick={close_click ? close_click : close_click} to="/">Home</Link></li>
            <li className={`${li_class}`}> <a className='block' onClick={close_click ? close_click : close_click} href="#book_taxi_form">Booking</a></li>
            <li className={`${li_class}`}> <a className='block' onClick={close_click ? close_click : close_click} href="#about">About</a></li>
            <li className={`${li_class}`}> <a className='block' onClick={close_click ? close_click : close_click} href="#our_pricing">Tarrif</a></li>
            {/* <li className={`${li_class}`}> <a className='block' onClick={close_click ? close_click : close_click} href="#our_vehicles">Vehicles</a></li> */}
            {/* <li className={`${li_class}`}> <a className='block' onClick={close_click ? close_click : close_click} href="/#">Contact</a></li> */}
        </ul>
    )
}

export default NavList