import React from "react";
import about from "../assets/images/aboutgif.gif";

const About = () => {
  return (
    <div className="container mx-auto py-10 md:py-16 px-5" id="about">
      <div className=" flex items-center gap-y-4  md:gap-y-0  gap-x-0 sm:gap-x-0 md:gap-x-36 flex-wrap md:flex-nowrap">
        <div className="sm:w-[100%]  md:w-[40%]">
          <img src={about} alt="about" />
        </div>
        <div className="w-full md:w-[60%]">
          <h5 className="text-[#e30d16] font-semibold">ABOUT US</h5>
          <h3 className="text-[#000] mt-4 font-bold text-[40px] sm:text-[45px] md:text-[50px]">
            HAPPYGO ONE WAY TAXI
          </h3>
          <div className="sm:mt-2 md:mt-4 w-full md:w-[80%]">
            <div>
                <h3 className=" font-semibold">ONE WAY TAXI</h3>
                <p className="mt-1 pl-4 sm:pl-5 md:pl-6 lg:pl-7">No Return fare! Why need to pay more for one way Taxi, We're the most trusted one.</p>
            </div>
            <div className="mt-2">
                <h3 className=" font-semibold">ROUND TRIP</h3>
                <p className="mt-1 pl-4 sm:pl-5 md:pl-6 lg:pl-7">Your pick-up address can be anywhere in pick-up city and drop address can be.</p>
            </div>
            <div className="mt-2">
                <h3 className=" font-semibold">AIRPORT PICKUP</h3>
                <p className="mt-1 pl-4 sm:pl-5 md:pl-6 lg:pl-7">Be it welcoming your friend at the airport right on time or any emergency situation.</p>
            </div>
            <div className="mt-2">
                <h3 className=" font-semibold">OUTSTATION CAB</h3>
                <p className="mt-1 pl-4 sm:pl-5 md:pl-6 lg:pl-7">Our Outstation taxi are the best for short and long trips to spend quality time with.</p>
            </div>
            <div className="mt-2">
                <h3 className=" font-semibold">DROP TAXI</h3>
                <p className="mt-1 pl-4 sm:pl-5 md:pl-6 lg:pl-7">Book a Oneway drop taxi to take you to your destination in the town, anywhere, anytime.</p>
            </div>
            <div className="mt-2">
                <h3 className=" font-semibold">100% SAFETY</h3>
                <p className="mt-1 pl-4 sm:pl-5 md:pl-6 lg:pl-7">Verified drivers, an emergency alert button, and live ride tracking</p>
            </div>
          </div>
          <a
            href="#book_taxi_form"
            className="bg-[#e30d16] mt-6 text-white py-4 px-8 text-[16px] sm:text-[17px] md:text-[18px] inline-block uppercase skew-x-[-20deg] book_taxi"
          >
            <span className="skew-x-[20deg] inline-block ">Book Now</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
