import React from 'react'
import { PiCarSimple } from 'react-icons/pi';

const PriceCars = ({img , car_name  ,amount }) => {
    return (
        <div className='bg-white py-4 px-4 md:px-10 mr-0 sm:mr-2 md:mr-3 lg:mr-6'>
            <div className='pb-10'>
            <img src={img} alt="suvcar" className='w-[250px] mx-auto pt-3' />
            </div>
            <div className='bg-black text-white mt-8 pt-5 pb-8 relative'>
                <span className='car_icon_par'>
                    <PiCarSimple className='car_icon_child' />
                </span>
                <h4 className='font-bold text-center pt-10 text-[25px]'>{car_name}</h4>
                {/* <p className=' font-medium text-[14px] text-center'>{cat_trip}</p> */}
                <div className='grid grid-cols-2 mt-5 px-10'>
                    <span>Starts From</span>
                    <span className='text-right'>{amount}</span>
                </div>
                <div className='text-center mt-5 mx-10'>
                    <a href="#book_taxi_form" className='inline-block border-white border text-white py-3 px-6 w-full text-[14px] font-semibold uppercase hover:bg-[#e30d16] transition-all ease-linear'>Book now Taxi</a>
                </div>
            </div>
        </div>
    )
}

export default PriceCars