import React, { useState } from 'react'
import sky_logo from "../assets/images/happygo.jpg";
import { AiOutlineMail } from 'react-icons/ai';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiFillCloseCircle } from 'react-icons/ai';
import Lottie from "lottie-react"
import animationData from "../assets/images/phoneicon.json"

import NavList from './NavList';
const Header = ({setChangeLink}) => {

    const [openNav, setOpenNav] = useState(false)
    function openNavBat() {
        setOpenNav(true);
    }
    function closeNavBar() {
        setOpenNav(false);
    }
    return (
        <div className='container mx-auto pb-6 relative px-5'>
            <div className='flex flex-nowrap sm:flex-wrap lg:flex-wrap items-center gap-x-3 sm:gap-x-5 gap-y-3 lg:gap-x-10 my-2 justify-between'>
                <img onClick={()=>setChangeLink('home')} src={sky_logo} alt="logo" className='w-[120px] md:w-[160px] cursor-pointer h-[auto]' />
                <div className='hidden sm:hidden md:flex gap-x-4 items-center'>
                    <span className='relative w-[45px] h-[45px] bg-[#f2f2f2] rounded-full'>
                        <a href='mailto:onewaytaxihappygo@gmail.com'><AiOutlineMail className='text-[20px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' /></a>
                        </span>
                    <span>Email Address:
                        <br />
                        <a href="mailto:onewaytaxihappygo@gmail.com" className='font-semibold text-[18px]'>onewaytaxihappygo@gmail.com</a>
                    </span>
                </div>
                <div className='flex  items-center'>
              
                <span className='relative w-[35px] sm:w-[80px] h-[35px] sm:h-[80px] rounded-full' >  <a href="tel:+919092718886"  ><Lottie animationData={animationData} /></a></span>

                    <span className='text-[14px] md:text-[16px]'>Phone Number:
                        <br />
                        <a href="tel:+919092718886" className='font-semibold text-[15px] md:text-[18px]'>9092718886</a>
                    </span>
                </div>
                <a href="#book_taxi_form" className='hidden sm:hidden md:inline-block bg-[#e30d16] text-white py-4 px-8 text-[18px] uppercase book_taxi'>Book a Taxi</a>
            </div>
            <div className='py-3 text-white bg-[#e30d16] absolute bottom-[-25px] left-0 w-full'>
                <NavList ul_class="font-semibold text-[15px] hidden sm:hidden md:flex gap-x-6 ml-6 uppercase" li_class="hover:text-black transition-all ease-linear" />
                <span className='cursor-pointer inline-block  md:hidden' onClick={openNavBat} ><GiHamburgerMenu className='ml-4 text-md inline-block text-[22px]' /></span>
            </div>
            <div className={`fixed bg-white h-full w-[60%] top-0 left-0 z-50 transition-all ease-linear ${openNav ? '' : '-translate-x-[110%]'}`}>
                <div className='px-4 py-3 bg-[#e8f8f9]'>
                    <img src={sky_logo} alt="logo" className='w-[180px] mx-auto px-5' />
                </div>
                <div className='py-6 px-4'>
                    <NavList ul_class="font-semibold text-[14px] flex flex-col gap-x-6 ml-6 uppercase" li_class="pt-3 border-b-2 pb-3" close_click={closeNavBar} />
                    <span onClick={closeNavBar} className='absolute top-4 -right-3 cursor-pointer'><AiFillCloseCircle className='text-[25px] text-[#e30d16]' /></span>
                </div>
            </div>
        </div>
    )
}

export default Header