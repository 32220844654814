import React from 'react'
import temp from "../assets/images/temp.jpg";
import Form from './Form';

const Banner = () => {
    return (
        <div className='bg-gray-300' id='home'>
            <div className='container mx-auto py-16 md:py-20 px-5'>
                <div className='flex flex-col justify-center items-center flex-wrap lg:gap-y-8 md:flex-nowrap gap-y-3  md:gap-y-0 gap-x-0  md:gap-x-3'>
                <div className='sm:w-[100%] md:w-[60%]'>
                        <Form />
                    </div>
                    <div className='sm:w-[100%] md:w-[40%]'>
                        <h4 className='text-[28px] sm:text-[30px] md:text-[35px] font-semibold'>ANYTIME & ANYWHERE! WE'VE GOT YOU</h4>
                        <p className='text-[16px] sm:text-[17px] md:text-[19px]'>Hire our taxi for Hassle free Pickup and Drop of your choice.</p>
                        <a href="#book_taxi_form" className='bg-[#e30d16]  mt-4 md:mt-6 text-white py-4 px-8  text-[16px] sm:text-[17px] md:text-[18px] inline-block uppercase hover:bg-black transition-all ease-linear'>Book a Taxi</a>
                    </div>
                  
                </div>
            </div>
        </div>
    )
}

export default Banner