import React, { Fragment, useEffect, useState } from 'react'
import About from './About';
import Banner from './Banner';
import Footer from './Footer';
import Header from './Header';
import Look from './Look';
import PhoneComponent from './PhoneComponent';
import Price from './Price';
// import Ride from './Ride';
import ScrollUp from './ScrollUp';
import TopRoutes from './TopRoutes';
import WhatsappComponent from './WhatsappComponent';
import TermsandCondition from './TermsandCondition';
import PrivacyPolicy from './PrivacyPolicy';
import { animateScroll as scroll } from 'react-scroll';

const Home = () => {
    const [changeLink, setChangeLink] = useState('home');
    // alert(changeLink);
    useEffect(() => {
        if(changeLink){
            scroll.scrollToTop({ smooth: false });
        }
    }, [changeLink])
    
    return (
        <Fragment>
            <Header setChangeLink={setChangeLink}/>
            {changeLink == "home" ? (
                <Fragment>
                    <Banner />
                    <About />
                    <Price />
                    {/* <Ride /> */}
                    <TopRoutes />
                    <Look />
                    <WhatsappComponent />
                    <PhoneComponent />
                    <ScrollUp />
                </Fragment>
            ) : ""}
            {changeLink == "terms" ? (
                <Fragment>
                    <TermsandCondition />
                    <WhatsappComponent />
                    <PhoneComponent />
                    <ScrollUp />
                </Fragment>
            ) : ""}
            {changeLink == "policy" ? (
                <Fragment>
                    <PrivacyPolicy />
                    <WhatsappComponent />
                    <PhoneComponent />
                    <ScrollUp />
                </Fragment>
            ) : ""}
            <Footer setChangeLink={setChangeLink} />
        </Fragment>
    )
}

export default Home